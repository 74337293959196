import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import apiFetch from "../../../services/dataAccess";
import { ImageUrlFormatter } from "../../../services/urlFormatter";
import { Tab } from "@headlessui/react";
import {
  DateTimeFormatter,
  timeDeltaColumnClassName,
  timeDeltaSinceLastRecording,
} from "../../../services/stringFormatter";
import { useTranslation } from "react-i18next";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

export type LatestImage = {
  project: string;
  projectName: string;
  cameraNumber: string;
  cameraName: string;
  latestTimestamp: string;
  camActiveStatus: boolean;
};

export const AdminHome = () => {
  const { t } = useTranslation();
  const [lastestImageData, setLatestImageData] = useState<LatestImage[]>([]);

  const fetchTableData = () => {
    apiFetch("/latest")
      .then((response) => response.json())
      .then((data) => {
        setLatestImageData(data);
      });
  };

  useEffect(() => {
    fetchTableData();

    const interval = setInterval(() => {
      fetchTableData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const getCamActiveStatus = (active: boolean) => {
    if (active) {
      return t('admin:cameraIsActive');
    }
    return t('admin:cameraIsInactive');
  };

  const tabClasses =
    "inline-block px-2 py-1 text-lg rounded-lg text-panterra-100 hover:text-panterra-50 hover:bg-panterra-600 mx-2";
  const selectedTabClasses = "inline-block px-2 py-1 text-lg rounded-lg text-panterra-50 bg-panterra-800 mx-2";

  const pictures = (
    <div className="mx-auto">
      <table className="mx-auto">
        <tbody>
          {lastestImageData.map((latestImage) => (
            <tr key={`${latestImage.projectName}-${latestImage.cameraName}`}>
              <td className="border px-8 py-4 text-left">
                <div>
                  {latestImage.project} - {latestImage.cameraNumber}
                  <br />
                  {latestImage.projectName}
                  <br />
                  {latestImage.cameraName}
                  <br />
                  {getCamActiveStatus(latestImage.camActiveStatus)}
                  <br />
                  {latestImage.latestTimestamp}
                  <br />
                  {timeDeltaSinceLastRecording(latestImage.latestTimestamp)}
                  <br />
                </div>
              </td>
              <td className="border">
                <Link to={`/projects/${latestImage.project}/${latestImage.cameraNumber}/zoom`}>
                  <img
                    className="h-96"
                    src={ImageUrlFormatter(
                      latestImage.project,
                      latestImage.cameraNumber,
                      latestImage.latestTimestamp,
                      "small",
                    )}
                    alt=""
                  />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const oldTable = (
    <table className="mx-auto">
      <thead>
        <tr className="">
          <th className="border px-8 py-4 text-center">{t('admin:projectIdentifier')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:preview')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:deltaT')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:projectName')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:camera')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:siteviewLink')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:lastRecordingTimestamp')}</th>
          <th className="border px-8 py-4 text-center">{t('admin:isActive')}</th>
        </tr>
      </thead>
      <tbody>
        {lastestImageData.map((latestImage) => (
          <tr key={`${latestImage.projectName}-${latestImage.cameraName}`}>
            <td className="border px-6 py-2 text-center">
              <Link
                className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
                to={`/projects/${latestImage.project}`}
              >
                {latestImage.project}
              </Link>
            </td>
            <td
              className="relative border bg-cover bg-center"
              style={{
                backgroundImage: `url(${ImageUrlFormatter(
                  latestImage.project,
                  latestImage.cameraNumber,
                  latestImage.latestTimestamp,
                  "small",
                )})`,
              }}
            >
              <Link
                className="absolute inset-0"
                to={`/projects/${latestImage.project}/${latestImage.cameraNumber}/zoom`}
              ></Link>
            </td>
            <td className={timeDeltaColumnClassName(latestImage.latestTimestamp, latestImage.camActiveStatus)}>
              {timeDeltaSinceLastRecording(latestImage.latestTimestamp)}
            </td>
            <td className="border px-6 py-2 text-center">
              <Link
                className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
                to={`/projects/${latestImage.project}`}
              >
                {latestImage.projectName}
              </Link>
            </td>
            <td className="border px-6 py-2 text-center">
              <Link
                className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
                to={`/projects/${latestImage.project}/${latestImage.cameraNumber}`}
              >
                {latestImage.cameraName}
              </Link>
            </td>
            <td className="border px-6 py-2 text-center">
              <a
                className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://timefactory.panterra.de/admin/embed.php?lepro=${latestImage.project}&lacam=${parseInt(
                  latestImage.cameraNumber,
                  10,
                )}&ta=faf22213c3aab1f0`}
              >
                {t("siteView")}
              </a>
            </td>
            <td className="border px-8 py-4 text-left">{DateTimeFormatter(latestImage.latestTimestamp)}</td>
            <td className="border px-8 py-4 text-center">{getCamActiveStatus(latestImage.camActiveStatus)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );


  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<LatestImage>[]>(
    () => [
      {
        accessorFn: row => row.project,
        id: 'project',
        cell: info => <Link
          className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
          to={`/projects/${info.getValue()}`}
        >
          {`${info.getValue()}`}
        </Link>,
        header: () => <span>{t('admin:projectIdentifier')}</span>,
      },
      {
        accessorFn: row => ImageUrlFormatter(
          row.project,
          row.cameraNumber,
          row.latestTimestamp,
          "small",
        ),
        id: 'preview',
        cell: ({ cell, row }) => <Link
          className="absolute inset-0"
          to={`/projects/${row.original.project}/${row.original.cameraNumber}/zoom`}
        ></Link>,
        header: () => <span>{t('admin:preview')}</span>,
      },
      {
        accessorFn: row => row.latestTimestamp,
        id: 'deltaT',
        header: t('admin:deltaT'),
        cell: ({ cell, row }) => timeDeltaSinceLastRecording(row.original.latestTimestamp),
      },
      {
        accessorFn: row => `${row.projectName}`,
        id: 'projectName',
        cell: ({ cell, row }) => <Link
          className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
          to={`/projects/${row.original.project}`}
        >
          {row.original.projectName}
        </Link>,
        header: () => <span>{t('admin:projectName')}</span>,
      },
      {
        accessorFn: row => `${row.cameraName}`,
        id: 'cameraNumber',
        cell: ({ cell, row }) => <Link
          className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
          to={`/projects/${row.original.project}/${row.original.cameraNumber}`}
        >
          {row.original.cameraName}
        </Link>,
        header: () => <span>{t('admin:camera')}</span>,
      },
      {
        accessorFn: row => `${row.project}/${row.cameraNumber}`,
        id: 'siteviewLink',
        cell: ({ cell, row }) => <a
          className="inline-block w-full rounded-md bg-panterra-800 px-2 py-2"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://timefactory.panterra.de/admin/embed.php?lepro=${row.original.project}&lacam=${parseInt(
            row.original.cameraNumber,
            10,
          )}&ta=faf22213c3aab1f0`}> {t("siteView")}</a>,
        header: () => <span>{t('admin:siteviewLink')}</span>,
      },
      {
        accessorFn: row => `${row.latestTimestamp}`,
        id: 'latestTimestamp',
        cell: ({ cell, row }) => `${DateTimeFormatter(row.original.latestTimestamp)}`,
        header: () => <span>{t('admin:lastRecordingTimestamp')}</span>,
      },
      {
        accessorFn: row => `${row.camActiveStatus}`,
        id: 'camActiveStatus',
        cell: ({cell, row}) => row.original.camActiveStatus ? t('admin:cameraIsActive') : t('admin:cameraIsInactive'),
        header: () => <span>{t('admin:isActive')}</span>,
      },
    ],
    [t]
  );

  const table = useReactTable({
    columns,
    data: lastestImageData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <div className={"container mx-auto pt-12 pb-8"}>
      <Tab.Group defaultIndex={0}>
        <Tab.List className={"flex flex-row justify-center pb-8"}>
          <Tab className={"outline-none"}>
            {({ selected }) => <span className={selected ? selectedTabClasses : tabClasses}>{t('admin:table')}</span>}
          </Tab>
          <Tab className={"outline-none"}>
            {({ selected }) => <span className={selected ? selectedTabClasses : tabClasses}>old {t('admin:table')}</span>}
          </Tab>
          <Tab className={"outline-none"}>
            {({ selected }) => <span className={selected ? selectedTabClasses : tabClasses}>{t('admin:pictures')}</span>}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <table className="mx-auto">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <th key={header.id} colSpan={header.colSpan} className="border px-6 py-2 text-center">
                          {header.isPlaceholder ? null : (
                            <div
                              className={
                                header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : ''
                              }
                              onClick={header.column.getToggleSortingHandler()}
                              title={
                                header.column.getCanSort()
                                  ? header.column.getNextSortingOrder() === 'asc'
                                    ? 'Sort ascending'
                                    : header.column.getNextSortingOrder() === 'desc'
                                      ? 'Sort descending'
                                      : 'Clear sort'
                                  : undefined
                              }
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table
                  .getRowModel()
                  .rows
                  .map(row => {
                    return (
                      <tr key={row.id} className="border px-6 py-2 text-center">
                        {row.getVisibleCells().map(cell => {

                          return (
                            <td
                              key={cell.id}
                              className={`border px-6 py-2 text-center ${cell.column.id === 'preview' ? 'relative' : ''} ${cell.column.id === 'deltaT' ? timeDeltaColumnClassName(cell.row.original.latestTimestamp, cell.row.original.camActiveStatus) : ''}`}
                              style={cell.column.id === 'preview' ? { backgroundImage: `url(${cell.getValue()})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Tab.Panel>
          <Tab.Panel>{oldTable}</Tab.Panel>
          <Tab.Panel>{pictures}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
